export const tableColumns = [
  {
    title: 'Thumbnail',
    dataIndex: 'thumbnail_path',
    key: 'thumbnail_path',
    align:'center'
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName',
    sorter: true,
    customFilterDropdown: true,
    width: '20%',
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    width: '20%',
    align: 'center'
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    sorter: true,
  },
  {
    title: 'Upload Time',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
  },
  {
    title: 'Labeled',
    dataIndex: 'labelled',
    key: 'labelled',
    sorter: true,
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];
